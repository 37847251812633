@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.heading {
  font-family: "Gilroyultralight";
  font-size: 68px;
  line-height: 115px;
  text-align: center;
  color: #ffffff;
  font-weight: lighter;
}

.gradient-text {
  background: linear-gradient(135deg, #f52a9d, #ffb1dd, #642af5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subheading {
  font-family: "Gilroyultralight";
  color: #ffffff;
  font-size: 26px;
  line-height: 50px;
  text-align: center;
  max-width: 700px;
}

.herosection {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 15rem;
  margin-top: 0rem;
  padding-top: 14rem;
  gap: 1.875em;
  height: auto;
}

span {
  letter-spacing: 1.25px;
}

@media (max-width: 1228px) {
  .heading {
    font-size: 60px !important;
    line-height: 90px !important;
    width: 565px !important;
  }
  .subheading {
    font-size: 20px !important;
    line-height: 44px !important;
  }
  .Primary {
    visibility: hidden !important;
  }
  .herosection {
    margin: 4rem 9rem;
    gap: 1em;
    margin-top: 0rem;
    padding-top: 10rem;
  }
}

@media (max-width: 821px) {
  .heading {
    font-size: 60px !important;
    line-height: 85px !important;
    width: 565px !important;
  }
  .subheading {
    font-size: 19px !important;
    line-height: 40px !important;
  }
  .Primary {
    visibility: hidden !important;
  }
  .herosection {
    margin: 4rem 7rem;
    margin-top: 0rem;
    padding-top: 10rem;
  }
}

@media (max-width: 630px) {
  .heading {
    font-size: 42px !important;
    line-height: 70px !important;
    width: 400px !important;
  }
  .subheading {
    font-size: 18px !important;
    line-height: 32px !important;
  }
  .Primary {
    visibility: hidden !important;
  }
  .herosection {
    margin: 5rem;
    gap: 1.5em !important;
    margin-top: 0rem;
    padding-top: 10rem;
  }
}

@media (max-width: 500px) {
  .heading {
    font-family: "Gilroylight" !important;
    font-size: 32px !important;
    line-height: 50px !important;
    width: 300px !important;
  }
  .subheading {
    font-size: 14px !important;
    line-height: 30px !important;
    max-width: 300px !important;
  }
  .herosection {
    margin: 3rem;
    margin-top: 0rem;
    padding-top: 10rem;
    gap: 1.5em !important;
    height: 80vh;
  }
}
