* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.p-inputtext {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    border-radius: 4px;
    border: none;
    border: 2px solid #ffffff;
    background: white;
    color: #b5b5b5;
    &:focus-visible {
        outline: none;
        border: 2px solid transparent !important;
        box-shadow: none !important;
    }
}

.p-input-icon-right i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem !important;
    right: 5%;
    cursor: pointer;
}

.container {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    padding: 0 10px;
    z-index: 2;
}

.container1 {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 0 10px;
    z-index: 2;
}

.container2 {
    width: 1100px;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    padding: 20px 30px;
    z-index: 2;
}

.max_Width {
    margin: auto;
    max-width: 1400px;
}


/* .aln_Pos {
  position: relative;
  top: 100px;
} */

.pa_Un {
    padding: unset !important;
}

.pa_25_ {
    padding: 0 25px;
}

.pa_TB {
    padding: 110px 25px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_W {
    flex-wrap: wrap;
}

.flex_D {
    flex-direction: column;
}

.g_25 {
    gap: 25px;
}

.g_15 {
    gap: 15px;
}

.g_5 {
    gap: 5px;
}

.curSor {
    cursor: pointer;
}

.main {
    padding: 25px;
}

.tiTTle {
    height: 100px;
    font-size: 36px;
}

.ti_SubTTle {
    font-size: 22px;
    line-height: 1.6;
    color: #a8a8a8;
    text-align: center;
}

.bg_B {
    background: #151413;
}

.text_W {
    color: #fff;
}

.text_B {
    color: #151413;
}

.maP_ {
    width: auto;
    height: 500px;
    object-fit: contain;
    background-size: cover;
    background-position: center;
}

@media (max-width: 800px) {
    .tiTTle {
        height: 80px;
        font-size: 30px;
    }
    .ti_SubTTle {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .grid_T {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .team_Staff {
        width: -webkit-fill-available;
    }
    .pa_TB {
        padding: 30px 15px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .container {
        width: 100%;
        padding: 20px;
    }
    .container1 {
        width: 100%;
        /* padding: 20px; */
    }
    .container2 {
        width: 100%;
        /* padding: 20px; */
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1140px;
        padding: 20px;
    }
    .container1 {
        width: 1140px;
    }
    .container2 {
        width: 900px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container2 {
        width: 800px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container2 {
        width: 700px;
    }
}

@media (min-width: 1400px) {
    .container {
        /* width: 1370px; */
        padding: 20px;
    }
    .container1 {
        width: 1350px;
    }
    .container2 {
        width: 1000px;
    }
}