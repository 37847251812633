.blog-content-section {

    .blog-card-section {
        width: 100%;
        text-align: left;
        background: #131313;

        h2 {
            font-size: 40px;
            color: rgb(255, 255, 255);
            min-height: 150px;
            justify-content: flex-start;
        }

        .blogs-heading {
            font-size: 24px;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            i {
                font-size: 10px;
                color: #FFFFFF;
                padding: 8px;
                border: 2px solid #EE781C;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .pagination {
            color: white;

        }

        .p-paginator {
            color: #FFFFFF !important;
            background: transparent !important;
        }

        .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            font-size: 18px;
            background: #EE781C;
            border-color: #EE781C;
            color: #FFFFFF;
            margin: 0 5px;
        }

        .p-paginator-page,
        .p-paginator-next,
        .p-paginator-last,
        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-current {
            color: white;
            width: 35px;
            height: 35px;
        }

        .p-link:focus {
            box-shadow: 0 0 0 0.2rem #f8f8f8;
        }
    }
}

.card-content {

    .p-card .p-card-body {
        padding: 0 !important;
    }

    .bloG {
        width: 445px;
    }

    .new {
        height: 400px;
        // width: 400px;
        border-radius: 15px;
        background: #232323;
        box-shadow: 0 -1px 7px 0px rgb(190 175 175 / 81%);

        .blog-img {
            display: flex;
            justify-content: center;
            padding: 15px;

            img {
                width: 100%;
                border-radius: 8px;
                object-fit: cover;
            }
        }

        .blog-text {
            margin: 0 1rem;

            span {
                color: #ababab;

            }
        }
    }

    .p-card .p-card-content {
        display: grid;
    }

}

.blog-content-section .gold-plan,
.blog-content-section .platinum-plan {
    min-height: 230px;
    display: grid;
    align-items: center;
}

.services-button {
    display: flex;
    justify-content: center;
    align-items: center;
}


.blog-content-section h2.related-section-heading {
    font-size: 32px;
    color: #FFFFFF;
}


.p-card .p-card-content,
.blog-content-section .card-content .new {
    height: auto;
}


.blOG_Item {
    width: 430px;
    min-height: 380px;
    background: #232323;
    padding: 15px;
    border-radius: 10px;
    justify-content: flex-start;
}

.blOG_ {
    width: 400px;
    height: 231px;
    border-radius: 10px;
    object-fit: cover;
}

.blOG_L {
    color: #ababab;
}

.blOG_V {
    width: 383px;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.bloG_Nav {
    font-size: 10px;
    color: #FFFFFF;
    padding: 8px;
    border: 2px solid #EE781C;
    border-radius: 50%;
    cursor: pointer;
}


@media screen and (max-width: 1240px) {
    .blog-content-section {
        .blog-card-section {
            h2 {
                font-size: 30px;
            }

            .card-content .new {
                height: 320px;

                .blog-text {
                    margin: 0 1rem;
                }
            }

            .blogs-heading {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .blog-content-section {
        .card-content {
            display: grid !important;
        }

        .card-content .new .blog-img img {
            width: 80%;
            border-radius: 12px;
            height: 214px;
        }

        .col-section {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .blog-content-section {
        .card-content {

            .new {
                .blog-img {
                    align-items: center;

                    img {
                        width: 80%;
                        border-radius: 12px;
                        height: 240px;
                    }
                }
            }
        }

        .col-section {
            width: 100%;
        }
    }

    .blog-content-section h2.related-section-heading {
        font-size: 28px;
        padding: 2rem 0 0 2rem;
    }
}

@media (max-width: 490px) {
    .blog-content-section {

        .blog-card-section h2 {
            font-size: 28px;
            padding: 0;
            text-align: center;
        }

        .card-content {

            .new {
                .blog-img {
                    align-items: flex-start;

                    img {
                        width: 95%;
                        border-radius: 12px;
                        // height: fit-content;
                        height: auto;
                    }
                }

                .blog-text {
                    span {
                        font-size: 14px;
                    }

                    .blogs-heading {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .blog-content-section h2.related-section-heading {
        font-size: 26px;
    }
}

@media (max-width: 575px) {

    .card-content .bloG {
        width: -webkit-fill-available;
    }

    .blOG_ {
        width: 100%;
    }

    .blOG_Item,
    .blOG_L {
        width: -webkit-fill-available;
    }

    .blOG_V {
        font-size: 18px;
    }
}