* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-hero {
  width: 100%;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

.hero {
  background: url("/src/Media/holographic.jpg");
  background-size: 130%;
  height: 770px;
  width: 100%;
  background-position-y: 80%;
  background-position-x: 40%;
  background-repeat: no-repeat;
  animation: zoomIn 3s ease-in-out forwards;
}

@keyframes zoomIn {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 130%;
  }
}

.abovelayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  height: 770px;
  width: 100%;
  background-color: black;
  opacity: 40%;
}

@media (min-width: 641px) and (max-width: 1008px) {
  .hero {
    background-size: 150%;
    height: 700px;
  }
  .abovelayer {
    height: 700px;
  }
}

@media (max-width: 640px) {
  .hero {
    background-size: 150%;
    height: 650px;
  }
  .abovelayer {
    height: 650px;
  }
}

@media (max-width: 500px) {
  .abovelayer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    height: 500px;
    width: 100%;
    background-color: black;
    opacity: 60%;
  }
  .hero {
    background: url("/src/Media/holographic.jpg");
    background-size: 130%;
    height: 500px;
    width: 100%;
    background-position-y: 80%;
    background-position-x: 40%;
    background-repeat: no-repeat;
  }
}
