@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.client-section {
  width: 100%;
  background-color: black;
  padding: 7.5rem;
}

.client-heading {
  padding-right: 60px;
}

.client-heading-text {
  font-family: "Gilroylight";
  color: white;
  font-size: 74px;
}

.client-details-text {
  font-family: "Gilroylight";
  color: #ffffff;
  font-size: 20px;
  line-height: 36px;
  max-width: 548px;
}

.client-brief {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-logos {
  display: grid;
  gap: 80px;
  /* Adjust spacing */
  grid-template-columns: repeat(4, 1fr);
  /* 3 equal columns */
  max-width: 1400px;
  /* Adjust based on your layout */
  margin: auto;
  /* Center the container */
  padding-top: 100px;
  align-items: center;
  justify-content: center;
}

.logo-one,
.logo-four,
.logo-five,
.logo-six,
.logo-seven,
.logo-eight {
  width: 100%;
  height: 60px;
  /* Fixed height for equal sizing */
  /* Ensures the image fits within */
}

.logo-three {
  width: 100%;
  height: 50px;
}

.logo-two {
  width: 100%;
  height: 100px;
}

.logo-five {
  width: 100%;
  height: 100px;
}

@media (max-width: 1242px) {
  .client-heading-text {
    font-family: "Gilroylight";
    color: white;
    font-size: 50px;
    width: 255px;
  }
  .client-details-text {
    font-family: "Gilroylight";
    color: #ffffff;
    font-size: 16px;
    line-height: 28px;
    max-width: 548px;
  }
}

@media (max-width: 900px) {
  .client-logos {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    gap: 60px;
    padding-top: 60px;
  }
  .logo-five,
  .logo-six {
    width: 100%;
    height: 50px;
    /* Fixed height for equal sizing */
    /* Ensures the image fits within */
  }
  .logo-one {
    width: 100%;
    height: 50px;
  }
  .logo-three {
    height: 40px;
  }
  .logo-two {
    height: 120px;
  }
  .logo-four {
    height: 40px;
  }
  .client-heading-text {
    font-family: "Gilroylight";
    color: white;
    font-size: 42px;
  }
  .client-details-text {
    font-family: "Gilroylight";
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    max-width: 400px;
  }
  .client-section {
    width: 100%;
    background-color: black;
    padding: 5rem;
  }
}

@media (max-width: 760px) {
  .client-details-text {
    font-family: "Gilroylight";
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    max-width: 400px;
  }
}

@media (max-width: 700px) {
  .client-section {
    width: 100%;
    background-color: black;
    padding: 4rem;
  }
  .client-logos {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    gap: 60px;
    padding-top: 40px;
  }
  .logo-one,
  .logo-four,
  .logo-five,
  .logo-six {
    height: 50px;
  }
  .logo-three {
    height: 30px;
  }
  .logo-two {
    height: 80px;
  }
  .client-brief {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .client-heading-text {
    font-family: "Gilroylight";
    color: white;
    font-size: 36px;
    text-align: center;
  }
  .client-heading {
    padding-right: 0px;
  }
  .client-details-text {
    font-family: "Gilroylight";
    color: #ffffff;
    font-size: 12px;
    line-height: 20px;
    max-width: 440px;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .logo-three {
    height: 30px;
  }
  .logo-one,
  .logo-six {
    height: 100px;
  }
  .logo-two {
    height: 60px;
  }
  .client-logos {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    gap: 40px;
    padding-top: 30px;
  }
  .client-section {
    width: 100%;
    background-color: black;
    padding: 3rem;
  }
  .client-heading-text {
    font-family: "Gilroylight";
    color: white;
    font-size: 30px;
  }
  .client-details-text {
    font-family: "Gilroylight";
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    max-width: 500px;
  }
  .client-logos > div {
    border: 1px solid #ffffff;
    /* White border */
    border-radius: 10px;
    /* Rounded corners */
    padding: 20px;
    /* Space inside the border */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    /* Light background */
    transition: all 0.3s ease-in-out;
  }
  .client-logos > div:hover {
    border-color: #184eff;
    /* Change border color on hover */
    transform: scale(1.05);
    /* Slight zoom effect */
  }
}

@media (max-width: 425px) {
  .client-logos {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    gap: 50px;
    padding-top: 60px;
  }
  .logo-one,
  .logo-four,
  .logo-five,
  .logo-six {
    height: 34px;
  }
  .logo-three {
    height: 20px;
  }
  .logo-two {
    height: 80px;
  }
}
