/* // .footer-section-black {
//     background-color: #000000;

//     .footer-links {
//         display: grid;
//         justify-items: self-start;
//         grid-template-columns: repeat(5, auto);
//         padding: 25px 0;

//         .logosection {
//             display: grid;
//             justify-content: center;
//             align-items: center;
//             margin-bottom: 20px;

//             .footer-icons {
//                 display: flex;
//                 justify-content: space-between;

//                 .socialmedia-icon a {
//                     color: #EE781C;
//                     width: 56px;
//                     font-size: 25px;
//                     display: flex;
//                 }
//             }
//         }

//         .quick-links {
//             h4 {
//                 color: #EE781C;
//                 margin-bottom: 10px;
//             }

//             ul {
//                 list-style: none;
//             }

//             ul li {
//                 padding: 8px 0;
//                 color: #C7C7C7;
//             }

//             a {
//                 text-decoration: none;
//                 color: #C7C7C7;
//             }

//             p {
//                 color: #C7C7C7;
//             }
//         }
//     }

//     .p-divider .p-divider-content {
//         background-color: #848484;
//         height: 1px;
//         width: 100%;
//     }

//     .footer-sec {
//         color: #FFFFFF;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         .policy-section {
//             display: flex;
//             align-items: center;
//             // margin: 0 80px;
//             justify-content: center;

//             a {
//                 margin-right: 20px;
//                 color: #747474;
//                 text-decoration: none;
//                 transition: color 0.2s ease;
//             }

//             a:nth-child(2):hover,
//             a:nth-child(3):hover {
//                 color: white;
//             }
//         }

//         .logo-sec {
//             display: flex;
//             align-items: center;
//             // margin: 0 70px;
//             justify-content: center;

//             img {
//                 width: 120px !important;
//                 filter: invert(0.6);
//             }
//         }
//     }
// }


// @media screen and (max-width: 992px) {
//     .footer-section-black {

//         .footer-links {
//             grid-template-columns: repeat(2, auto);
//             // justify-items: stretch;

//             .logosection {
//                 display: grid;
//                 justify-content: start;
//                 margin-left: 20px;
//                 margin-bottom: 20px;
//             }
//         }

//         .footer-sec {
//             display: grid;
//             justify-content: center;
//             align-items: center;
//             margin-top: 20px;

//             .policy-section {
//                 display: grid;
//                 justify-content: center;

//                 a {
//                     text-align: center;
//                     padding-bottom: 15px;
//                     margin-right: 0;

//                     &:last-child {
//                         padding-bottom: 10px;
//                     }
//                 }

//             }

//             .logo-sec {
//                 justify-content: center;
//                 margin: auto;

//                 img {
//                     width: 100px !important;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width: 991px) {
//     .footer-section-black {

//         .p-6 {
//             padding: 1.5rem !important;
//         }

//         .footer-links {
//             grid-template-columns: repeat(3, auto);

//             .logosection {
//                 margin-left: 5px;
//             }

//             .col,
//             .m-3 {
//                 margin: 1rem 0px !important;
//             }

//             // .footer-news{
//             //     position: relative;
//             //     left: 10%;
//             // }
//         }

//         .footer-sec {
//             display: grid;
//             justify-content: center;
//             align-items: center;
//             margin-top: 20px;

//             .policy-section {
//                 display: grid;
//                 justify-content: center;

//                 a {
//                     text-align: center;
//                     padding-bottom: 15px;
//                     margin-right: 0;

//                     &:last-child {
//                         padding-bottom: 10px;
//                     }
//                 }

//             }

//             .logo-sec {
//                 justify-content: center;
//                 margin: auto;

//                 img {
//                     width: 100px !important;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width:768px) {
//     .footer-section-black {

//         .footer-links {
//             grid-template-columns: repeat(2, auto);
//             justify-items: center;

//             .logosection {
//                 justify-items: center;
//                 margin-left: 0;
//                 display: flex;
//                 flex-direction: column-reverse;

//                 .footer-icons {
//                     padding-top: 25px;
//                 }

//                 .footer-icons .socialmedia-icon a {
//                     justify-content: center;
//                 }

//                 img {
//                     padding-top: 20px;
//                 }
//             }

//             .quick-links {
//                 text-align: center;
//             }
//         }

//         .footer-sec {
//             display: flex !important;
//             flex-direction: column-reverse;

//             .policy-section {
//                 display: grid;
//                 justify-content: center;

//                 a {
//                     text-align: center;
//                     padding-bottom: 15px;
//                     margin-right: 0;

//                     &:last-child {
//                         padding-bottom: 30px;
//                     }
//                 }

//             }

//             .logo-sec {
//                 display: grid;
//                 justify-content: center;
//                 grid-template-columns: repeat(2, auto);

//                 img {
//                     width: 100px !important;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width:490px) {
//     .footer-section-black {

//         .footer-links {
//             grid-template-columns: repeat(1, auto);
//             justify-items: center;

//             .logosection {
//                 justify-items: center;
//                 margin-left: 0;
//                 display: flex;
//                 flex-direction: column-reverse;

//                 .footer-icons {
//                     padding-top: 25px;
//                 }

//                 .footer-icons .socialmedia-icon a {
//                     justify-content: center;
//                 }

//                 img {
//                     padding-top: 20px;
//                 }
//             }

//             .quick-links {
//                 text-align: center;
//             }
//         }

//         .footer-sec {
//             display: flex !important;
//             flex-direction: column-reverse;

//             .policy-section {
//                 display: grid;
//                 justify-content: center;

//                 a {
//                     text-align: center;
//                     padding-bottom: 15px;
//                     margin-right: 0;

//                     &:last-child {
//                         padding-bottom: 30px;
//                     }
//                 }

//             }

//             .logo-sec {
//                 display: grid;
//                 justify-content: center;
//                 grid-template-columns: repeat(1, auto);

//                 img {
//                     width: 100px !important;
//                 }
//             }
//         }
//     }
// } */

@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.footersec {
  width: 100%;
  background-color: black;
  padding: 80px 100px;
}

.building-on-trust {
  font-family: "Gilroyultralight";
  color: rgb(255, 255, 255);
  font-size: 54px;
}

.footer-small-button {
  font-family: "Gilroyregular";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  background-color: transparent;
  color: #ffffff;
  border: 1.4px solid;
  border-color: rgb(255, 255, 255);
  padding: 18px 32px;
  font-size: 18px;
  cursor: pointer;
}

.list-o {
  text-decoration: none;
  font-family: "Gilroyultralight";
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.list-u {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.company-heading,
.provider-heading,
.connect-heading {
  padding-bottom: 36px;
}

.company-heading-text,
.provider-heading-text,
.connect-heading-text {
  font-family: "Gilroyregular";
  color: rgb(255, 255, 255);
  font-size: 22px;
}

a {
  text-decoration: none;
}

.mid-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
  gap: 90px;
}

.bot-footer {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.white-socialmedia {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.right-reserved-text,
.terms-text,
.privacy-text {
  font-family: "Gilroyultralight";
  color: rgb(155, 155, 155);
  font-size: 16px;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.emblem-two {
  position: absolute;
  bottom: 30px;
}

.emblem-one {
  position: absolute;
  bottom: 30px;
}

.connect-section {
  max-width: 420px;
}

.connect-sub-heading {
  max-width: 420px;
}

@media (max-width: 1142px) {
  .mid-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 80px;
    column-gap: 100px;
    row-gap: 40px;
  }
  .building-on-trust {
    text-align: center;
  }
  .bot-footer {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 140px;
  }
  .terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
  }
  .emblem-two {
    position: static;
    bottom: 0px;
  }
  .emblem-one {
    position: static;
    bottom: 0px;
  }
}

@media (max-width: 1066px) {
  .bot-footer {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    row-gap: 60px;
    max-width: 450px;
  }
  .footersec {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .right-reserved-text {
    max-width: 355px;
    text-align: center;
  }
}

@media (max-width: 648px) {
  .mid-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
    column-gap: 100px;
    row-gap: 40px;
  }
  .footersec {
    width: 100%;
    background-color: black;
    padding: 80px 20px;
  }
  .building-on-trust {
    font-size: 36px;
  }
  .bot-footer {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    max-width: 450px;
  }
  .company-heading-text,
  .provider-heading-text,
  .connect-heading-text {
    font-family: "Gilroyregular";
    color: rgb(255, 255, 255);
    font-size: 18px;
  }
  .list-o {
    text-decoration: none;
    font-family: "Gilroyultralight";
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  .list-u {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
  .white-facebook-icon,
  .white-linkedin-icon,
  .white-instagram-icon,
  .white-x-icon {
    width: 18px;
    height: 18px;
  }
  .right-reserved-text,
  .terms-text,
  .privacy-text {
    font-size: 14px;
  }
  .emblem-two img,
  .emblem-one img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 450px) {
  .footersec {
    width: 100%;
    background-color: black;
    padding: 50px 80px;
  }
  .company-heading,
  .provider-heading,
  .connect-heading {
    padding-bottom: 24px;
  }
  .building-on-trust {
    font-size: 20px;
    text-align: left;
  }
  .list-u {
    gap: 16px;
  }
  .mid-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    column-gap: 100px;
    row-gap: 40px;
  }
  .white-socialmedia {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .white-facebook-icon,
  .white-linkedin-icon,
  .white-instagram-icon,
  .white-x-icon {
    width: 18px;
    height: 18px;
  }
  .connect-sub-heading a {
    font-size: 10px;
  }
  .provider-sub-heading a,
  .company-sub-heading a {
    font-size: 12px;
  }
  .connect-section {
    width: 240px;
  }
  .right-reserved-text,
  .terms-text,
  .privacy-text {
    font-size: 12px;
  }
  .emblem-two img,
  .emblem-one img {
    width: 70px;
    height: 70px;
  }
  .company-heading-text,
  .provider-heading-text,
  .connect-heading-text {
    font-size: 14px;
  }
  .list-u {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .company-sub-heading ul,
  .provider-sub-heading ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .company-section,
  .provider-section,
  .connect-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
