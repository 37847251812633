/* .client-section {
    background-color: #141414;

    .ourClients-title {
        color: #FFF;
        font-size: 32px;
        margin-bottom: 2rem;
        padding: 1.5rem 0 0 0rem;
    }

    .ourClients-btn {
        background-color: #3a3a3a;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        margin: 0 2.5rem 0 0rem;
        margin-top: 2rem;
    }
}

.ourClients {
    padding: 4rem 0;
    background-image: url("../../Assets//Home/ClientSecBanner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .slick-list {
        overflow: hidden;
    }

    .ourClients-images {
        width: 200px !important;
    }

    .slick-slider {
        padding: 0px 30px;
        background-color: #00000099;
    }

    .slick-initialized .slick-slide {
        // width: 380px !important;
        width:300px !important;
    }

    .slick-track {
        display: flex;
        place-items: center;
        gap: 1rem;
    }

    .slick-prev:before {
        font-size: 35px;
        opacity: 1;
        line-height: 0;
    }

    .slick-next:before {
        font-size: 35px;
        opacity: 1;
        line-height: 0;
    }

    .slick-prev,
    .slick-next {
        display: none !important;
    }
}

@media (max-width: 500px) {
    .ourClients-title {
        font-size: 28px !important;
    }

    .ourClients {
        padding: 0;
    }
} */

@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.blog-section {
  width: 90%;
  margin: 6rem auto;
}

.blogs-text {
  font-family: "Gilroylight";
  color: rgb(0, 0, 0);
  font-size: 74px;
}

.blog-img-first {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
}

.blog-one:hover .blog-img-first {
  transform: scale(1.05);
  /* Slight zoom-in effect */
}

.blog-two:hover .blog-img-first {
  transform: scale(1.05);
  /* Slight zoom-in effect */
}

.blog-three:hover .blog-img-first {
  transform: scale(1.05);
  /* Slight zoom-in effect */
}

.blog-sub-heading-one {
  font-family: "Gilroyregular";
  color: rgb(0, 0, 0);
  font-size: 24px;
  padding-top: 28px;
  padding-bottom: 16px;
}

.blog-content-one {
  font-family: "Gilroylight";
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding-bottom: 24px;
}

.blog-one,
.blog-two,
.blog-three {
  justify-content: flex-start;
  text-align: left;
  line-height: 28px;
  margin-bottom: 86px;
  flex: 1 1 calc(33.33% - 40px);
  max-width: calc(33.33% - 40px);
  cursor: pointer;
  overflow: hidden;
}

.small-arrow {
  font-family: "Gilroylight";
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: none;
  color: #335fd8;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: gap 0.4s ease-in-out;
}

.blog-one:hover .small-arrow {
  gap: 20px;
  /* Increase gap between text and icon */
}

.blog-two:hover .small-arrow {
  gap: 20px;
  /* Increase gap between text and icon */
}

.blog-three:hover .small-arrow {
  gap: 20px;
  /* Increase gap between text and icon */
}

.blog-post {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
  padding-top: 60px;
}

.blog-small-button {
  font-family: "Gilroyregular";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  background-color: transparent;
  color: #000000;
  border: 1.4px solid;
  border-color: rgb(0, 0, 0);
  padding: 18px 32px;
  font-size: 18px;
  cursor: pointer;
  margin: auto;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    gap 0.3s ease-in-out;
}

.blog-image-container-one {
  width: 100%;
  /* Set a fixed height for uniformity */
  overflow: hidden;
  /* Hides any overflow from scaling */
  display: flex;
  /* Ensures the image stays inside */
}

.blog-small-button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  gap: 30px;
  /* Increases the gap between text and icon on hover */
}

.blog-small-button:hover .blogs-blackarrow {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease-in-out;
}

.testing {
  font-family: "Gilroylight";
  letter-spacing: 1px;
}

@media (max-width: 1193px) {
  .blog-one,
  .blog-two,
  .blog-three {
    flex: 1 1 calc(50% - 20px);
    /* 2 divs per row */
    max-width: calc(50% - 20px);
    line-height: 28px;
    margin-bottom: 30px;
  }
  .blog-post {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    padding-top: 48px;
    padding-bottom: 36px;
  }
  .blog-sub-heading-one {
    padding-top: 18px;
    padding-bottom: 12px;
  }
  .blogs-text {
    font-size: 60px;
  }
  .blog-section {
    margin: 4rem auto;
  }
  .blog-sub-heading-one {
    font-family: "Gilroylight";
    color: rgb(0, 0, 0);
    font-size: 22px;
    padding-top: 24px;
    padding-bottom: 14px;
  }
  .blog-content-one {
    font-family: "Gilroylight";
    color: rgb(0, 0, 0);
    font-size: 14px;
    padding-bottom: 24px;
  }
}

@media (max-width: 700px) {
  .blog-section {
    margin: 3rem auto;
  }
  .blog-one,
  .blog-two,
  .blog-three {
    flex: 1 1 100%;
    /* Each div takes full width */
    max-width: 100%;
  }
  .blog-section {
    width: 85%;
  }
  .blog-img-first {
    height: 250px;
    width: 100%;
  }
  .blog-sub-heading-one {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .blog-content-one {
    font-size: 14px;
    padding-bottom: 16px;
    line-height: 24px;
  }
  .blog-small-button {
    font-family: "Gilroyregular";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: transparent;
    color: #000000;
    border: 1.2px solid;
    border-color: rgb(0, 0, 0);
    padding: 14px 18px;
    font-size: 14px;
    cursor: pointer;
    margin: auto;
  }
  .blogs-blackarrow {
    width: 14px;
    height: 14px;
  }
  .small-arrow {
    font-family: "Gilroylight";
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    border: none;
    color: #335fd8;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  .blogs-bluearrow {
    width: 18px;
    height: 18px;
  }
  .blogs-text {
    font-size: 54px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .blogs-text {
    text-align: center;
    font-size: 36px;
  }
  .blog-img-first {
    height: 150px;
    width: 100%;
  }
  .blog-small-button {
    border: 1px solid;
  }
}
