.career-page {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("../../Assets/Career/BannerImg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: auto;
    position: relative;

    .career-page-text {
        color: #FFFFFF;
        min-height: 200px;

        h2 {
            font-size: 40px;

            span {
                color: #EE781C;
                font-weight: bolder;
            }

            // .text-dot {
            //     display: inline-block;
            //     width: 20px;
            //     height: 20px;
            //     background-color: #EE781C;
            //     border-radius: 50%;
            // }

        }

        p {
            font-size: 24px;
            line-height: 1.6;
        }
    }

    .career-page-form {
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
        }

        .contactus-section-banner-form {
            display: flex;
            width: -webkit-fill-available;
            // padding: 3rem 0;
            justify-content: space-between;

            .career-page-form-content {
                display: flex;
                align-items: flex-end;
            }

            .submit-btn {
                display: flex;
                justify-content: space-evenly;
                padding: 1rem 0;

                .p-button {
                    padding: 0 25px;
                    height: 50px;
                    font-size: 16px;
                    background-color: transparent;
                    box-sizing: border-box;
                    border: 1px solid #848484;
                    border-radius: 10px;
                    transition: none !important;
                    gap: 10px;

                    &:hover {
                        border: 2px solid #EE781C;
                        color: #FFFFFF;
                    }

                    &:focus {
                        box-shadow: none !important;
                    }
                }

                .submit-button {
                    background-color: #EE781C;
                    color: #FFFFFF;


                    &:hover {
                        border: 2px solid #EE781C;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .career-page-form-content {
        width: 40%;

        p {
            color: #FFFFFF;
            font-size: 34px;
            line-height: 1.6;

            // .text-dot {
            //     display: inline-block;
            //     width: 10px;
            //     height: 10px;
            //     background-color: #EE781C;
            //     border-radius: 50%;
            // }

            span:first-child {
                color: #EE781C;
                font-weight: 800;
            }

            span {
                color: #FFFFFF;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}

.error-msg {
    color: #ff4040;
    padding-top: 1rem;
}

@media screen and (max-width: 1200px) {
    .career-page {
        height: 1200px;

        .career-page-form {
            .contactus-section-banner-form {
                flex-wrap: wrap;
                justify-content: center;
            }

            .career-page-form-content {
                width: 100% !important;
            }
        }

        .career-page-form-content {
            width: 100%;

            p {
                padding: 4rem 0;
                font-size: 24px;
                text-wrap: pretty;
                text-align: center;

                // .text-dot {
                //     width: 8px;
                //     height: 8px;
                // }
            }
        }
    }
}

@media screen and (max-width:864px) {
    .career-page {

        .career-page-text {
            color: #FFFFFF;

            h2 {
                font-size: 34px;

                // .text-dot {
                //     width: 10px;
                //     height: 10px;
                // }
            }

            p {
                font-size: 22px;
            }
        }

        .career-page-form .contactus-section-banner-form {
            padding: 0 0rem 0rem;

            .contactus-form {
                padding: 1.5rem;

                .submit-btn {
                    flex-wrap: wrap;
                    gap: 2rem;
                }
            }
        }

        .career-page-form-content {
            width: 100%;

            p {
                padding: 4rem 0;
                font-size: 20px;
                text-wrap: pretty;

                // .text-dot {
                //     width: 8px;
                //     height: 8px;
                // }
            }
        }
    }
}

@media screen and (max-width:490px) {
    .career-page {
        height: 1350px;

        .career-page-text {

            h2 {
                font-size: 26px;

                // .text-dot {
                //     width: 10px;
                //     height: 10px;
                // }
            }

            p {
                font-size: 18px;
            }
        }

        .career-page-form .contactus-form .w-max {
            flex-direction: column !important;

            .col-6 {
                width: 100% !important;
            }
        }

        .career-page-form-content {
            p {
                padding: 2rem 0;
            }
        }
    }
}