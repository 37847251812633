@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.bgblack {
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;
}

.tertiary {
  font-family: "Gilroylight";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  transition: gap 0.4s ease-in-out;
}

.tertiary:hover {
  gap: 30px;
  /* Increase gap between text and icon */
}

.subcontent-one-text {
  font-family: "Gilroylight";
  color: #ffffff;
  font-size: 20px;
  line-height: 36px;
  max-width: 560px;
}

.subcontent-one {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.content-one-text {
  font-family: "Gilroyultralight";
  background: linear-gradient(90deg, #184eff, #ee0979);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 110px;
}

.content-one {
  opacity: 0;
}

@media (max-width: 1260px) {
  .content-one-text {
    font-size: 80px;
  }
  .subcontent-one-text {
    font-size: 18px;
    line-height: 32px;
    max-width: 500px;
  }
  .bgblack {
    height: 534px;
    /* box-shadow: inset 0 0 15px 7px rgb(42, 110, 161); */
  }
}

@media (max-width: 924px) {
  .content-one-text {
    font-size: 60px;
  }
  .subcontent-one-text {
    font-size: 16px;
    line-height: 30px;
    max-width: 380px;
  }
  .tertiary {
    gap: 14px;
    font-size: 18px;
  }
  .whatwedo-small-arrow {
    width: 16px;
    height: 16px;
  }
  .bgblack {
    height: 467px;
    /* box-shadow: inset 0 0 15px 7px rgb(42, 110, 161); */
  }
}

@media (max-width: 736px) {
  .content-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bgblack {
    height: 400px;
    /* box-shadow: inset 0 0 15px 7px rgb(42, 110, 161); */
  }
  .subcontent-one-text {
    text-align: center;
  }
}

@media (max-width: 520px) {
  .bgblack {
    height: 325px;
    /* box-shadow: inset 0 0 15px 5px rgb(42, 110, 161); */
  }
  .tertiary {
    gap: 12px;
    font-size: 16px;
  }
  .whatwedo-small-arrow {
    width: 16px;
    height: 16px;
  }
  .content-one-text {
    font-size: 48px;
  }
  .subcontent-one-text {
    font-size: 14px;
    line-height: 28px;
    max-width: 300px;
  }
}

@media (max-width: 500px) {
  .bgblack {
    height: 325px;
    /* box-shadow: inset 0 0 15px 3px rgb(42, 110, 161); */
  }
  .tertiary {
    gap: 12px;
    font-size: 16px;
  }
  .whatwedo-small-arrow {
    width: 16px;
    height: 16px;
  }
  .content-one-text {
    font-size: 40px;
  }
  .subcontent-one-text {
    font-size: 12px;
    line-height: 28px;
    max-width: 260px;
  }
}
