.team_Staff {
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 30px 10px;
    background: #0000 none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    font-size: 25px;
    margin: auto;
}

.team_Staff img {
    border-radius: 25px;
    background-position: center;
    background-size: cover;
    object-fit: cover;
}

.t_Name {
    font-size: 25px;
    font-weight: 600;
}

.t_Role {
    font-size: 16px;
    font-weight: 400;
}


.grid_T {
    grid-gap: 20px;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 100%;
}

@media (max-width: 420px) {

    .team_Staff {
        width: -webkit-fill-available;
    }

    .grid_T{
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
}