.whatwebelieve-section {
    background-color: #090909;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 4rem;
    gap: 2rem;
.container .whatwe{
    display: flex;
    gap: 156px;
}
    .whatwebelieve-section-content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 50%;

        h2 {
            font-size: 36px;
            text-align: left;
        }

        p {
            font-size: 22px;
            margin-top: 2rem;
            line-height: 1.6;
            color: #CBCBCB;
        }
    }

    .team-section {
        width: 35%;
        padding: 30px auto;
        margin: 30px auto;
    }

    .team-members-list {
        position: relative;
        background-color: #2E2E2E;
        margin: 0 auto;
        border-radius: 10px;
        padding: 1.5rem;
    }

    .team-member {
        transition: opacity 1s ease-in-out;
        padding: 1rem;
        width: 100%;
        transition-duration: 600ms;
        transition-timing-function: ease;

        img {
            background-color: #FFFFFF;
            width: 20%;
            height: 20%;
            padding: 0.2rem;
            border-radius: 50%;
        }
    }

    .team-member.top {
        background-color: #1A1A1A;
        border-radius: 15px;

        img {
            opacity: 0.4;

            >div {
                display: none;
                opacity: 0.6;
            }
        }
    }

    .team-member>div {
        display: flex;
        align-items: center;
        gap: 30px;

        p {
            padding: 0.5rem 0 0;
        }
    }

    .team-member.delayed {
        opacity: 1;
        z-index: 1;
        position: absolute;
        border-radius: 15px;
        width: -webkit-fill-available;
        margin-right: 20px;
        animation: slide-up 2s ease-in-out;
        transition-property: transform;
        background-color: #EE781C;

        img {
            width: 20%;
            height: 20%;
        }
    }

    @keyframes slide-up {
        0% {
            top: 23px;
            opacity: 1;
            transform: rotate(0deg);
        }

        100% {
            // background-color: #2E2E2E;
            top: 10px;
            opacity: 1;
            transform: rotate(4deg);
        }
    }
}
@media screen and (max-width: 1200px) {
    .whatwebelieve-section {
        // flex-direction: column;
        // padding: 3rem;
        // .container .whatwe{
        //     // flex-direction: column;
        // padding: 3rem;
        // }
        // .whatwebelieve-section-content {
        //     max-width: 100%;
        //     margin: 0 auto;
        //     text-align: center;

        //     h2 {
        //         font-size: 30px;
        //         text-align: center;
        //     }

        //     p {
        //         font-size: 22px;
        //     }
        // }

        .team-section {
            // width: 60%;
            h3 {
                font-size: 16px;
            }

            p {
                font-size: 15px;
            }
        }

        .team-member.delayed {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 1008px) {
    .whatwebelieve-section {
        flex-direction: column;
        padding: 3rem;
        .container .whatwe{
            flex-direction: column;
        padding: 3rem;
        }
        .whatwebelieve-section-content {
            max-width: 100%;
            margin: 0 auto;
            text-align: center;

            h2 {
                font-size: 30px;
                text-align: center;
            }

            p {
                font-size: 22px;
            }
        }

        .team-section {
            width: 60%;
            h3 {
                font-size: 18px;
            }

            p {
                font-size: 20px;
            }
        }

        .team-member.delayed {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 768px) {
    .whatwebelieve-section {
        padding: 0rem;
        .container .whatwe{
            // flex-direction: column;
        padding: 0rem;
        }
        .team-section {
            width: 80%;
        }
    }
}

@media screen and (max-width: 490px) {
    .whatwebelieve-section {
        .container .whatwe{
            // flex-direction: column;
        padding: 0rem;
        gap: 80px;
        }
        .whatwebelieve-section-content {
            h2 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }

        .team-section {
            width: 100% !important;
            h3 {
                font-size: 13px;
            }

            p {
                font-size: 13px;
            }
        }
    }
}