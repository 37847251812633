@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.wilson {
  font-family: "Gilroyultralight";
  color: #000000;
  font-size: 110px;
}

.wilson-details {
  font-family: "Gilroylight";
  color: #000000;
  font-size: 20px;
  line-height: 36px;
  padding-top: 16px;
  padding-bottom: 36px;
  max-width: 540px;
}

.founder-section {
  width: 90%;
  margin: 6.5rem auto;
}

.socialmedia-icons {
  display: flex;
  gap: 76px;
}

.bw-image {
  width: 100%;
  max-height: 560px;
  /* filter: grayscale(100%) contrast(150%); */
  object-fit: cover;
  object-position: center 20%;
}

.founder-image {
  position: relative;
}

/* .blue-circle {
  position: absolute;
  top: 10%;
  left: 15%;
  width: 167px;
  height: 167px;
  background: linear-gradient(#5ecfff, #cf09ee);
  border-radius: 50%;
  filter: blur(60px);
} */

.founder-rotate {
  position: absolute;
  top: 85%;
  left: 80%;
  animation: rotate360 10s linear infinite;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tag-rotate {
  width: 160px;
  height: 160px;
}

.founder-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transition: opacity 3s ease-out;
}

.founder-content {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1190px) {
  .founder-content {
    padding-top: 0px;
    padding-bottom: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .founder-section {
    width: 90%;
    margin: 4rem auto;
  }
  .wilson-details {
    text-align: center;
    font-size: 15px;
    line-height: 34px;
    padding-top: 16px;
    padding-bottom: 36px;
    max-width: 440px;
  }
  .wilson {
    text-align: center;
    font-size: 5em;
  }
}

@media (max-width: 730px) {
  .founder-content {
    padding-right: 0px;
  }
}

@media (max-width: 730px) {
  .founder-content {
    padding-top: 0px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .founder-section {
    width: 80%;
    margin: 5rem auto;
  }
  .wilson {
    text-align: center;
    font-size: 3.5em;
  }
  .wilson-details {
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    padding-top: 16px;
    padding-bottom: 36px;
    max-width: 440px;
  }
}

@media (max-width: 550px) {
  .founder-section {
    width: 70%;
    margin: 5rem auto;
  }
  .wilson {
    text-align: center;
    font-size: 2.5em;
  }
  .wilson-details {
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    padding-top: 16px;
    padding-bottom: 36px;
    max-width: 440px;
  }
  .socialmedia-icons {
    display: flex;
    gap: 60px;
  }
  .social-media-icon {
    width: 20px;
    height: 20px;
  }
  .tag-rotate {
    width: 120px;
    height: 120px;
  }
  .founder-image {
    max-width: 300px;
  }
  .blue-circle {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 500px) {
  .founder-section {
    width: 60%;
    margin: 4rem auto;
  }
  .wilson {
    text-align: center;
    font-size: 2em;
  }
  .wilson-details {
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    padding-top: 16px;
    padding-bottom: 36px;
    max-width: 440px;
  }
  .socialmedia-icons {
    display: flex;
    gap: 40px;
  }
  .social-media-icon {
    width: 16px;
    height: 16px;
  }
  .tag-rotate {
    width: 100px;
    height: 100px;
  }
  .founder-image {
    max-width: 300px;
  }
  .blue-circle {
    width: 100px;
    height: 100px;
  }
}
