@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroythin";
  src: url("/public/fontface/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyregular";
  src: url("/public/fontface/Gilroy-Regular.ttf") format("truetype");
}

.container-service {
  text-align: center;
  width: 90%;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.servicepage {
  display: flex;
  flex-direction: column;
  padding: 0px 60px 80px 60px;
  gap: 24px;
  align-items: center;
}

.extra-services {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: center;
  margin-bottom: 40px;
}

.extra-services.open {
  max-height: 1500px;
  /* Adjust based on content */
}

.heading2 {
  font-family: "Gilroylight";
  font-size: 68px;
  color: #000000;
}

.subheading2 {
  font-family: "Gilroylight";
  color: #000000;
  font-size: 24px;
  line-height: 50px;
  max-width: 1000px;
}

.service-one,
.service-two,
.service-three,
.service-four,
.service-five,
.service-six,
.service-seven,
.service-eight {
  text-align: left;
  margin-bottom: 24px;
  flex: 1 1 40%;
  overflow: hidden;
}

.img-first {
  width: 100%;
  height: 384px;
  object-fit: cover;
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
}

.img-second {
  width: 100%;
  height: 384px;
  object-fit: cover;
  /* object-position: center 30%; */
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.img-three {
  width: 100%;
  height: 384px;
  object-fit: cover;
  /* object-position: center 60%; */
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.img-four {
  width: 100%;
  height: 384px;
  object-fit: cover;
  /* object-position: center 90%; */
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.img-five {
  width: 100%;
  height: 384px;
  object-fit: cover;
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.img-six {
  width: 100%;
  height: 384px;
  object-fit: cover;
  /* object-position: center 30%; */
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.img-seven {
  width: 100%;
  height: 384px;
  object-fit: cover;
  /* object-position: center 60%; */
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.img-eight {
  width: 100%;
  height: 384px;
  object-fit: cover;
  /* object-position: center 90%; */
  margin-bottom: 26px;
  transition: transform 0.4s ease-in-out;
}

.service-one:hover .img-first {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-two:hover .img-second {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-three:hover .img-three {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-four:hover .img-four {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-five:hover .img-five {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-six:hover .img-six {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-seven:hover .img-seven {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.service-eight:hover .img-eight {
  transform: scale(1.02);
  /* Slight zoom-in effect */
}

.heading3,
.heading4,
.heading5,
.heading6,
.heading7,
.heading8,
.heading9,
.heading10 {
  font-family: "Gilroylight";
  color: #000000;
  font-size: 30px;
}

.subheading3,
.subheading4,
.subheading5,
.subheading6,
.subheading7,
.subheading8,
.subheading9,
.subheading10 {
  font-family: "Gilroylight";
  color: #242424;
  font-size: 20px;
  line-height: 36px;
  padding-top: 18px;
  padding-bottom: 26px;
}

.secondary {
  font-family: "Gilroylight";
  display: flex;
  align-items: center;
  gap: 24px;
  background: none;
  border: none;
  color: #335fd8;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  transition: gap 0.4s ease-in-out;
}

a {
  text-decoration: none;
}

.gradient-text {
  font-family: "Gilroyultralight";
}

.service-one:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-two:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-three:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-four:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-five:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-six:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-seven:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.service-eight:hover .secondary {
  gap: 30px;
  /* Increase gap between text and icon */
}

.Primary-two {
  font-family: "Gilroyregular";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  background-color: transparent;
  color: #000000;
  border: 1.5px solid;
  border-color: rgb(0, 0, 0);
  padding: 20px 40px;
  font-size: 20px;
  cursor: pointer;
  margin: auto;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    gap 0.3s ease-in-out;
}

.Primary-two:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  gap: 40px;
  /* Increases the gap between text and icon on hover */
}

.Primary:hover .response-blackarrow {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease-in-out;
}

.service-section {
  margin-bottom: 36px;
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: center;
}

.response-blackarrow {
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 1190px) {
  .heading2 {
    font-size: 54px;
  }
  .subheading2 {
    font-size: 20px;
    line-height: 40px;
    max-width: 900px;
  }
  .heading3,
  .heading4,
  .heading5,
  .heading6,
  .heading7,
  .heading8,
  .heading9,
  .heading10 {
    font-size: 26px;
  }
  .subheading3,
  .subheading4,
  .subheading5,
  .subheading6,
  .subheading7,
  .subheading8,
  .subheading9,
  .subheading10 {
    font-size: 16px;
    line-height: 32px;
    padding-top: 14px;
    padding-bottom: 16px;
  }
}

@media (max-width: 1008px) {
  .service-one,
  .service-two,
  .service-three,
  .service-four,
  .service-five,
  .service-six,
  .service-seven,
  .service-eight {
    flex: 1 1 100% !important;
  }
  .heading2 {
    font-size: 54px;
  }
  .subheading2 {
    font-size: 20px;
    line-height: 40px;
    max-width: 900px;
  }
  .container-service {
    margin: 3rem auto;
  }
  .servicepage {
    display: flex;
    flex-direction: column;
    padding: 0px 60px 40px 60px;
    gap: 24px;
    align-items: center;
  }
  .service-section,
  .extra-services {
    gap: 40px;
  }
  .heading3,
  .heading4,
  .heading5,
  .heading6,
  .heading7,
  .heading8,
  .heading9,
  .heading10 {
    font-size: 26px;
  }
  .subheading3,
  .subheading4,
  .subheading5,
  .subheading6,
  .subheading7,
  .subheading8,
  .subheading9,
  .subheading10 {
    font-size: 16px;
    line-height: 32px;
    padding-top: 14px;
    padding-bottom: 16px;
  }
  .secondary {
    gap: 12px;
    font-size: 18px;
  }
  .response-arrow {
    width: 18px;
    height: 18px;
  }
  .Primary-two {
    font-family: "Gilroyregular";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: transparent;
    color: #000000;
    border: 1.5px solid;
    border-color: rgb(0, 0, 0);
    padding: 16px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: auto;
  }
  .response-blackarrow {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 640px) {
  .service-one,
  .service-two,
  .service-three,
  .service-four,
  .service-five,
  .service-six,
  .service-seven,
  .service-eight {
    flex: 1 1 100% !important;
  }
  .heading2 {
    font-size: 42px;
  }
  .subheading2 {
    font-size: 16px;
    line-height: 32px;
    max-width: 900px;
  }
  .container-service {
    margin: 3rem auto;
  }
  .servicepage {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 40px 30px;
    gap: 24px;
    align-items: center;
  }
  .service-section {
    gap: 40px;
  }
  .heading3,
  .heading4,
  .heading5,
  .heading6,
  .heading7,
  .heading8,
  .heading9,
  .heading10 {
    font-size: 20px;
  }
  .subheading3,
  .subheading4,
  .subheading5,
  .subheading6,
  .subheading7,
  .subheading8,
  .subheading9,
  .subheading10 {
    font-size: 14px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .secondary {
    gap: 10px;
    font-size: 14px;
  }
  .response-arrow {
    width: 16px;
    height: 16px;
  }
  .Primary-two {
    font-family: "Gilroyregular";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: transparent;
    color: #000000;
    border: 1.2px solid;
    border-color: rgb(0, 0, 0);
    padding: 16px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: auto;
  }
  .response-blackarrow {
    width: 18px;
    height: 18px;
  }
  .container-service {
    width: 85%;
  }
}

@media (max-width: 500px) {
  .heading2 {
    font-size: 32px;
  }
  .subheading2 {
    font-size: 14px;
    line-height: 28px;
    max-width: 900px;
  }
  .heading3,
  .heading4,
  .heading5,
  .heading6,
  .heading7,
  .heading8,
  .heading9,
  .heading10 {
    font-size: 18px;
  }
  .subheading3,
  .subheading4,
  .subheading5,
  .subheading6,
  .subheading7,
  .subheading8,
  .subheading9,
  .subheading10 {
    font-size: 14px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .servicepage {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 40px 30px;
    gap: 16px;
    align-items: center;
  }
  .Primary-two {
    font-family: "Gilroyregular";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    background-color: transparent;
    color: #000000;
    border: 1px solid;
    border-color: rgb(0, 0, 0);
    padding: 12px 18px;
    font-size: 14px;
    cursor: pointer;
    margin: auto;
  }
}
