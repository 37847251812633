.forbidden-main{
    background: rgba(55, 57, 92, 1);
    width: 100%;
    height: 929px;
    overflow: hidden;
}
.forbidden-contain{
    display: flex;
    align-items: center;
    height: 960px;
    gap: 3rem;
    line-height: 120px;
}
.awcontent{
    font-size: 91px;
    font-weight: 800;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
.just-error{
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
.forbidden-small {
    line-height: 61px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
.carimg{
    width: 526px;
    height: 710px
}
.for-button {
    font-size: 24px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    border-radius: 54px;
    width: 300px;
    color: rgba(255, 255, 255, 1);
    border-color: transparent;
    background-color: rgba(117, 163, 152, 1);
    text-align: center;
    display: inline-block;
    text-decoration: none;
  }
.forbidden-button{
    margin-top: 40px;
}
@media (min-width:1200px) and (max-width:1399px){
    .carimg {
        width: 489px;
        height: 673px;
    }
    .awcontent {
        font-size: 70px;
    }
    .just-error {
        font-size: 40px;
    }
    .forbidden-small {
        line-height: 52px;
        font-size: 25px;
    }
}
@media (min-width:992px) and (max-width:1199px){
    .carimg {
        width: 470px;
        height: 600px;
    }
    .awcontent {
        font-size: 56px;
    }
    .just-error {
        font-size: 32px;
    }
    .forbidden-small {
        line-height: 45px;
        font-size: 20px;
    }
    .forbidden-contain {
        
        gap: 1rem;
        line-height: 106px;
    }
    .for-button {
        font-size: 21px;
        width: 250px;
    }
}
@media (min-width:950px) and (max-width:991px){
    .carimg {
        width: 438px;
        height: 550px;
    }
    .awcontent {
        font-size: 57px;
    }
    .just-error {
        font-size: 40px;
    }
    .forbidden-small {
        line-height: 55px;
        font-size: 20px;
    }
    .forbidden-contain {
        
        gap: 1rem;
        line-height: 106px;
    }
    .for-button {
        font-size: 19px;
        width: 230px;
    }
}
@media (min-width:800px) and (max-width:949px){
    .carimg {
        width: 400px;
        height: 500px;
    }
    .awcontent {
        font-size: 40px;
    }
    .just-error {
        font-size: 25px;
    }
    .forbidden-small {
        line-height: 40px;
        font-size: 14px;
    }
    .forbidden-contain {
        line-height: 80px;
    }
    .for-button {
        font-size: 19px;
        width: 230px;
    }
}
@media (min-width:575px) and (max-width:799px){
    .carimg {
        width: 318px;
        height: 420px;
        margin-top: 25%;
    }
    .awcontent {
        font-size: 55px;
    }
    .just-error {
        font-size: 35px;
    }
    .forbidden-small {
        line-height: 51px;
        font-size: 22px;
    }
    .forbidden-contain {
        line-height: 90px;
        flex-direction: column;
        gap: 0rem;
    }
    .forbidden-main {
        height: 1025px;
    }
}
@media (min-width:425px) and (max-width:574px){
    .carimg {
        width: 280px;
        height: 365px;
        margin-top: 26%;
    }
    .awcontent {
        font-size: 46px;
    }
    .just-error {
        font-size: 27px;
    }
    .forbidden-small {
        line-height: 43px;
        font-size: 16px;
    }
    .forbidden-contain {
        line-height: 90px;
        flex-direction: column;
        gap: 0rem;
    }
    .forbidden-main {
        height: 960px;
    }
    .for-button {
        font-size: 20px;
        width: 230px;
    }
}
@media (min-width:320px) and (max-width:424px){
    .carimg {
        width: 254px;
        height: 329px;
        margin-top: 30%;
    }
    .awcontent {
        font-size: 33px;
    }
    .just-error {
        font-size: 23px;
    }
    .forbidden-small {
        line-height: 45px;
        font-size: 19px;
    }
    .forbidden-contain {
        line-height: 70px;
        flex-direction: column;
        gap: 0rem;
    }
    .forbidden-main {
        height: 930px;
    }
    .for-button {
        font-size: 18px;
        border-radius: 40px;
        width: 217px;
    }
}
