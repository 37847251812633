@font-face {
  font-family: "Gilroylight";
  src: url("/public/fontface/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroyultralight";
  src: url("/public/fontface/Gilroy-UltraLight.ttf") format("truetype");
}

.navbar {
  font-family: "Gilroyultralight", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Align logo, nav, and hamburger */
  width: 100%;
  height: 6.875em;
  padding: 0 2rem;
  /* Add some padding */
  backdrop-filter: blur(30px);
  background: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}

.navbar-dark {
  font-family: "Gilroyultralight", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Align logo, nav, and hamburger */
  width: 100%;
  height: 6.875em;
  padding: 0 2rem;
  /* Add some padding */
  backdrop-filter: blur(30px);
  background: rgb(0, 0, 0);
}

/* Logo Styling */

.logo {
  padding-left: 40px;
}

.logo img {
  height: 45px;
  opacity: 100%;
  /* Adjust logo size */
}

/* Center Navigation */

.navlinks {
  display: flex;
  gap: 3.25em;
}

.navlinks a {
  font-family: "gilroylight";
  text-decoration: none;
  color: #ffffff;
  font-size: 1.25em;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.navlinks a:hover {
  font-size: 1.3em;
  font-family: "gilroyregular";
  /* Increase size */
  /* Change font */
  transform: scale(1.05);
  /* Slight zoom effect */
}

.nav-item {
  position: relative;
}

.nav-item::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 50px;
  /* Matches the gap size */
  background: transparent;
  pointer-events: auto;
  /* Allows hover detection */
}

.dropdown {
  position: absolute;
  top: 100%;
  top: calc(100% + 50px);
  /* Moves it 80px below */
  width: 200px;
  backdrop-filter: blur(30px);
  /* Same effect as navbar */
  background: rgb(0, 0, 0);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.nav-item:hover .dropdown {
  opacity: 1;
  transform: translateY(0);
}

.dropdown a {
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.1em;
  transition: all 0.3s ease-in-out;
}

/* Hover Effect for Dropdown Items */

.dropdown a:hover {
  background: linear-gradient(90deg, #003cff, #ee0979);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: scale(1.01);
}

/* Hamburger Menu (Hidden by default) */

.hamburger-menu {
  visibility: hidden;
}

.hamburger {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.hamburger span {
  display: block;
  width: 20px;
  height: 2px;
  background: #ffffff;
  opacity: 80%;
  transition: 0.3s ease;
}

.container-navbar {
  position: fixed;
  z-index: 61200;
  top: 0px;
  width: 100%;
}

.mobile-nav {
  flex-direction: column;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 5rem;
  text-align: center;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
}

.mobile-nav.show-menu {
  transform: translateX(0);
}

.mobile-nav a {
  font-family: "Gilroyregular", sans-serif;
  text-decoration: none;
  color: white;
  font-size: 1.5em;
  padding: 1rem;
  display: block;
  transition: color 0.3s ease;
}

.mobile-nav a:hover {
  background: linear-gradient(90deg, #003cff, #ee0979);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mobile-nav-item {
  margin-bottom: 10px;
  align-items: center;
}

.mobile-nav-item a {
  display: inline-block;
}

.mobile-dropdown {
  display: none;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
}

.mobile-dropdown a {
  font-size: 1.2em;
}

.mobile-nav-item:hover .mobile-dropdown {
  display: flex;
}

@media (max-width: 1024px) {
  .navlinks {
    gap: 2.1em;
  }
  .navlinks a {
    text-decoration: none;
    color: #ffffff;
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .navlinks {
    display: none;
    /* Hide menu in mobile */
  }
  .logo {
    padding-left: 0px;
  }
  .logo img {
    height: 30px;
    opacity: 80%;
    /* Adjust logo size */
  }
  .hamburger-menu {
    visibility: visible;
    z-index: 11;
    display: block;
    color: #ffffff;
  }
  .navbar {
    height: 5em;
    padding: 0 2rem;
    /* Add some padding */
  }
}
