.product-pages {
    width: 100%;
    height: 650px;
    position: relative;
    margin: auto;

    .product-page-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        filter: brightness(0.6);
    }

    .product-page-text {
        position: relative;
        z-index: 1;
        color: #fff;
        padding: 20px;
        display: grid;
        justify-content: center;
        top: 40%;

        h2 {
            font-size: 40px;
            text-transform: uppercase;
            text-align: center;

            span {
                color: #EE781C;
                font-weight: 600;
            }

        }

        p {
            font-size: 24px;
            line-height: 1.6;
            text-align: center;
            padding: 1rem 0;
        }
    }
}


.product-contents-section {
    background-color: #131313;
    padding: 4rem 2rem;

    .p-skeleton::after {
        animation: none !important;
    }
}

.product-square-section {
    border: 2px solid #454545;
    margin: 4rem 10rem;
    padding: 2rem;
    text-align: center;
    color: #FFFFFF;
    background-color: #131313;
    border-radius: 15px;

    h2 {
        font-size: 32px;
        margin-bottom: 1rem;
    }

    p {
        font-size: 22px;
        line-height: 1.4;
        margin-bottom: 1rem;
    }

    .product-square-content {
        box-sizing: border-box;
        padding: 2rem;
        margin: 2rem;
        background: #252525;
        box-shadow: -6.95345px 11.5891px 34.7672px rgba(0, 0, 0, 0.4);
        border-radius: 18px;

        .product-square-content-top-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .product-square-content-top-section-left {
                display: flex;
                align-items: center;

                span {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 1rem;

                    &.red-circle {
                        background-color: #FF0000;
                    }

                    &.orange-circle {
                        background-color: #FFA500;
                    }

                    &.green-circle {
                        background-color: #008000;
                    }
                }
            }

            .product-square-content-top-section-right {
                display: flex;
                align-items: center;

                img {
                    width: 50px;
                    height: 50px;
                    margin-right: 1rem;
                }

                h3 {
                    font-size: 24px;
                }
            }
        }

        .box-1 {
            display: flex;
            justify-content: center;
            position: relative;

            .product-square-content-skeleton {
                margin: 2rem;
                background-color: #131313;
                border-radius: 18px;
                padding: 2rem;
                width: 70%;
                max-width: 70%;

                >div {
                    background-color: #454545;
                    border-radius: 10px;
                    background-color: #EE781C4D;

                    &.skeleton1 {
                        max-width: 70%;
                        height: 4rem !important;
                    }

                    &.skeleton2 {
                        max-width: 40%;
                        height: 0.5rem !important;
                    }

                    &.skeleton3 {
                        max-width: 50%;
                        height: 0.5rem !important;
                    }
                }
            }


            .s {
                width: 300px;
                background-color: #FF0000;
                border-radius: 40px;
                height: 30px;
            }
        }

        .box-2 {
            display: flex;
            justify-content: center;

            .product-square-content-skeleton {
                margin: 2rem;
                background-color: #131313;
                border-radius: 18px;
                padding: 2rem;
                width: 70%;
                max-width: 70%;

                >div {
                    background-color: #454545;
                    border-radius: 10px;
                    background-color: #EE781C4D;

                    &.skeleton1 {
                        max-width: 60%;
                        height: 4rem !important;
                    }

                    &.skeleton2 {
                        max-width: 50%;
                        height: 0.5rem !important;
                    }

                    &.skeleton3 {
                        max-width: 40%;
                        height: 0.5rem !important;
                    }
                }
            }
        }

        .box-3 {
            display: flex;
            justify-content: center;

            .product-square-content-skeleton {
                background-color: #131313;
                border-radius: 18px;
                padding: 2rem;
                width: 34%;
                max-width: 32%;

                &:first-child {
                    margin: 2rem 2rem 2rem 0rem;
                }

                &:last-child {
                    margin: 2rem 0 2rem 2rem;
                }

                >div {
                    background-color: #454545;
                    border-radius: 10px;
                    background-color: #EE781C4D;

                    &.skeleton2 {
                        max-width: 60%;
                        height: 0.5rem !important;
                    }

                    &.skeleton1,
                    &.skeleton3 {
                        max-width: 80%;
                        height: 0.5rem !important;
                    }
                }
            }

        }

    }
}

.features {
    max-width: 350px;
    width: -webkit-fill-available;
    position: absolute;
    z-index: 1;
    right: 18rem;
    margin-top: 5rem;
}

.feature-section {
    background-color: #2a2a2a;
    border-radius: 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    :last-child {
        margin-bottom: 0;
    }

    &.n:nth-child(even) {
        position: relative;
        left: -3rem;
        width: 450px;
    }
}

.feature-card {
    display: flex;
    align-items: center;
    background-color: #2a2a2a;
    padding: 30px;
    border-radius: 60px;
    transition: background-color 0.3s ease;

    &.active {
        background-color: #ec6d22;
        width: 350px;
    }

    .iconn {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        padding: 10px;
        background-color: #252525;

        &:nth-child(even) {
            background-color: #FFFFFF33;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .titles {
        flex-grow: 1;
        color: #fff;
        display: flex;
        justify-content: center;
        margin: 0 1rem;
        text-wrap: nowrap;
    }

}

.statuss {
    position: relative;
    margin-right: 2rem;

    svg {
        background-color: #21F37566;
        border-radius: 50%;
        font-size: 28px;
    }

    .check-icon {
        background-color: #21F37566;
        border-radius: 50%;
        font-size: 28px;

    }

    img {
        width: 100%;
        height: 100%;
    }
}

.call-to-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1a1a1a;
    padding: 3rem;

    .cta-content {
        max-width: 50%;
        width: -webkit-fill-available;
        display: grid;
        justify-items: center;

        h2 {
            font-size: 2rem;
            color: #fff;
            margin-bottom: 20px;
        }

        p {
            color: #fff;
            font-size: 22px;
            margin-bottom: 20px;
        }

        .cta-buttons {
            display: flex;
            gap: 30px;

            .cta-btn {
                padding: 10px 20px;
                font-size: 1rem;
                font-weight: bold;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &.contact {
                    background-color: #EE781C;

                    a {
                        text-decoration: none;
                        color: #FFFFFF;
                    }


                    &:hover {
                        background-color: #e65c00;
                    }
                }

                &.visit {
                    border: 2px solid #EE781C;
                    background-color: transparent;
                    color: white;
                    text-transform: uppercase;
                    padding: 10px 20px;
                    position: relative;
                    font-weight: bold;
                    cursor: pointer;

                    a {
                        text-decoration: none;
                        color: #FFFFFF;
                    }

                    &:hover {
                        background-color: #333;
                    }


                }
            }
        }
    }

    .cta-image {
        img {
            width: 600px;
            border-radius: 10px;
            clip-path: polygon(80px 0%, 100% 0%, 100% 100%, 30px 100%);
        }
    }
}


.img_Pro {
    width: -webkit-fill-available;
    height: 500px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    filter: brightness(0.6);
}

.conT {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
}

.conT_Sub {
    font-size: 24px;
    line-height: 1.6;
}

.proD_Item {
    border: 2px solid #454545;
    border-radius: 15px;
    padding: 2rem;
}

.prO_Img {
    width: -webkit-fill-available;
    height: 500px;
    object-fit: contain;
}

.ctA_Img {
    width: 500px;
    object-fit: cover;
    clip-path: polygon(80px 0%, 100% 0%, 100% 100%, 30px 100%);
}

.cta_Btn {
    height: 50px;
    color: #fff;
    background-color: #ee781c;
    font-size: 16px;
    padding: 25px 0;
}

.a_Btn {
    text-decoration: unset;
    font-weight: 400;
}

@media screen and (max-width:1240px) {
    .product-pages {
        width: 100%;
        height: 500px;
    }

    .product-square-section {
        margin: 0rem;

        .features {
            right: 11rem;
        }

        .product-square-content .box-1,
        .product-square-content .box-2 {
            justify-content: flex-start
        }

        .product-square-content .box-3 {
            justify-content: space-around;
        }
    }

    .call-to-action {
        flex-wrap: wrap;
        justify-content: center;

        .cta-content {
            max-width: 70%;
            text-align: center;

            h2 {
                font-size: 1.5rem;
            }
        }

        .cta-image {
            margin-top: 2rem;

            img {
                width: 400px;
            }
        }
    }
}

@media screen and (max-width:964px) {
    .product-pages {
        width: 100%;
        height: 400px;

        .product-page-text {
            top: 40%;

            h2 {
                font-size: 32px;
            }

            p {
                font-size: 20px;
            }
        }
    }

    .product-square-section {

        .features {
            right: 4rem;
        }

        h2 {
            font-size: 26px;
        }

        p {
            font-size: 20px;
        }
    }


}

@media screen and (max-width:767px) {


    .product-square-section {
        padding: 2rem 1rem;

        .features {
            max-width: 275px;
        }

        .feature-section.n:nth-child(even) {
            width: 350px;
        }

        .feature-card {
            padding: 10px;

            &.active {
                width: 255px;
            }
        }

        .product-square-content {
            margin: 2rem 0 !important;

            .box-1 .product-square-content-skeleton {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width:490px) {

    .product-pages {
        width: 100%;
        height: 400px;

        .product-pages .product-page-img {
            object-fit: cover;
            top: 78px;
        }

        .product-page-text {
            top: 40%;

            h2 {
                font-size: 28px;
            }

            p {
                font-size: 18px;
            }
        }
    }

    .product-square-section {
        .features {
            right: 2rem;

            .feature-card .iconn {
                width: 40px;
                height: 40px;
            }
        }

        .product-square-content {
            padding: 1rem;
            margin: 1rem;

            .product-square-content-top-section {

                .product-square-content-top-section-left {

                    span {
                        width: 15px !important;
                        height: 15px !important;
                        margin-right: 0.5rem;
                    }
                }

                img {
                    width: 100px;
                }
            }

            .box-1 {

                .product-square-content-skeleton {
                    width: 100%;
                    max-width: 100%;
                    padding: 1rem;
                }
            }

            .box-2 {

                .product-square-content-skeleton {
                    padding: 1rem;
                    margin: 1rem 0;
                    width: 100%;
                    max-width: 100%;
                }
            }

            .box-3 {
                .product-square-content-skeleton {
                    padding: 1rem;
                    width: 50%;
                    max-width: 50%;
                }

            }

        }
    }

    .call-to-action {

        .cta-content {
            max-width: 100% !important;
            text-align: center;

            h2 {
                font-size: 1.2rem;
            }

            p {
                font-size: 18px;
            }
        }

        .cta-image {
            margin-top: 2rem;

            img {
                width: 300px;
            }
        }
    }
}

@media (max-width: 575px) {
    .ctA_Img {
        width: -webkit-fill-available;
    }
}