.sidetab-main {
  width: 85%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  margin: auto;

  .sidebar-wrapper {
    width: 25%;
    flex-shrink: 0;

    .sidebar {
      position: fixed;
      top: 100px;
      width: 100%;
      padding: 20px 10px;
      border-right: 1px solid #ddd;
      z-index: 10;

      &.scrolling {
        position: relative;
        top: auto;
      }

      a {
        display: block;
        padding: 10px;
        color: #333;
        text-decoration: none;
        font-weight: 500;
        font-size: 15px;

        &.active {
          font-weight: bold;
          color: orange;
        }
      }
    }

    &.mobile {
      width: 100%;
      position: sticky;
      top: 100px;
      z-index: 10;
      background: white;
      border-bottom: 1px solid #ddd;

      .sidebar {
        position: fixed;
        top: 100px;
        bottom: 0;
        background: white;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        display: flex;
        gap: 30px;
        justify-content: flex-start;
        // left: 1rem;

        a {
          flex: none;
          text-align: center;
          font-size: 14px;

          &.active {
            font-weight: bold;
            color: orange;
          }
        }
      }
    }
  }

  .terms-and-conditions {
    display: flex;
    align-items: stretch;
    gap: 65px;

    .content1 {
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      .title1 {
        font-size: 2.5rem;
        margin: 3rem 0;
      }

      .content-section {
        margin-top: 30px;
        margin-bottom: 60px;
        width: 100%;

        h2 {
          margin-bottom: 20px;
          font-size: 1rem;
          color: #222;
        }

        ul {
          margin-left: 1rem;
        }

        p {
          line-height: 1.8rem;
          color: #555;
          margin-left: 0.5rem;
        }
      }
    }
  }

  // Mobile-specific styling
  

  
}
@media (max-width: 990px) {
  .sidetab-main {
    width: 100%;
    padding: 0;
  }

  .terms-and-conditions {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .sidebar-wrapper {
    width: 100%;
    padding: 0;

    .sidebar {
      flex-direction: row;
      justify-content: flex-start;
      gap: 30px;
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
    }

    a {
      flex: none;
      font-size: 14px;
    }
  }

  .content1 {
    // width: 85%;
    box-sizing: border-box;
    padding-top: 100px;
    padding-left: 4rem;
    padding-right: 3rem;
  }
}

/* Ensure this is placed after other media queries */
@media (max-width: 450px) {
  .sidetab-main {
    width: 100%; 
  }

  .terms-and-conditions {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .sidebar-wrapper {
    width: 100%;
    padding: 0;

    .sidebar {
      flex-direction: row;
      justify-content: flex-start;
      gap: 30px;
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
    }

    a {
      flex: none;
      font-size: 14px;
    }
  }

  .content1 {
    box-sizing: border-box;
    padding-top: 100px;
    padding-left: 1rem !important;
    padding-right: 1rem !important; 
  }
}
