.contact-us-section {
    padding: 5rem 3rem;
    background-color: black;
    color: #FFF;
    z-index: 999;
    position: absolute;
    width: -webkit-fill-available;
}

.arT_C {
    width: 300px;
    height: 350px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0.25rem 0.5rem 0.75rem rgba(219, 201, 201, 0.25);
}

.content_Sec {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    background-position: center;
    background-size: cover;
    object-fit: cover;
}

.usa {
    background-image: url('../Assets/ContactUs/USA.webp');
}

.india {
    background-image: url('../Assets/ContactUs/India.webp');
}

.content_Sec button {
    color: black;
    padding: 1rem;
    background: white;
    width: fit-content;
    border-radius: 15px;
    border: unset;
    margin: 25px;
}

.sub_Content {
    display: flex;
    gap: 5px;
    flex-direction: column;
    background: linear-gradient(180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.3) 10%, hsl(0, 0%, 0%) 100%);
    padding: 25px;
}










.contact-us-section-title h3 {
    font-size: 2.5rem;
    margin: 2rem 0;
}

.contact-us-section-para {
    font-size: 22px;
    line-height: 1.8;

    a {
        color: #EE781C;
    }
}

.grid-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.location-cardds {
    margin-top: 12rem;
}

.location-cardd img,
.location-cardds img {
    max-width: 100%;
}

.card {
    display: grid;
    place-items: center;
    height: 350px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0.25rem 0.5rem 0.75rem rgb(219 201 201 / 25%);
}

.card>* {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.card__background {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
}

.card__content {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    height: 55%;
    width: 100%;
    background: linear-gradient(180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.3) 10%, hsl(0, 0%, 0%) 100%);
    justify-content: space-around;
    align-items: center;
}

.card__contents {
    display: flex;
    height: fit-content;
    width: 100%;
    padding-bottom: 50px;
    background: linear-gradient(180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.3) 10%, hsl(0, 0%, 0%) 100%);
    align-items: flex-end;
    justify-content: center;
}

.card__title {
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    color: #FFF;
}

.location-cardd .tag {
    color: black;
    position: absolute;
    margin-top: -200px;
    padding: 1rem;
    background: white;
    width: fit-content;
    border-radius: 15px;
    margin-left: -4rem;
}

.location-cardds .tag {
    color: black;
    position: absolute;
    margin-top: -250px;
    padding: 1rem;
    background: white;
    width: fit-content;
    border-radius: 15px;
    margin-left: -8rem;
}

.card__title p {
    margin-bottom: 5px;
}

.contactus-section-banner {
    width: 100%;
    background-image: url("../Assets/ContactUs/Contactus-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contactus-section-overlay {
    width: 100%;
    height: 1000px;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
}

.contactus-section-banner-content-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.contactus-section-banner-content {
    width: 42%;
}

.contactus-section-banner-content-section h2 {
    font-size: 60px;
}

.contactus-section-banner-content-section p {
    font-size: 24px;
    line-height: 1.6;
}

.contactus-section-banner-content-section h2 span {
    // display: inline-block;
    // width: 20px;
    // height: 20px;
    // background-color: #EE781C;
    // border-radius: 50%;
    // margin-left: 5px;
    color: #EE781C;
    font-weight: 900;
}


.contactus-form {
    padding: 2rem;
    border-radius: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    // z-index: 999;
    z-index: auto;
}

.contactus-form .p-inputtext,
.contactus-form .p-inputtextarea {
    width: 100%;
    padding: 0.5rem 1rem;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ffffff;
    background: none;
    color: #b8b8b8;
}

.contactus-form .field {
    margin-bottom: 0;
}

.contactus-form label:focus-visible {
    color: #EE781C;
    font-size: 20px;
}

.contactus-form .field.col .p-inputtext-sm:focus+label,
.contactus-form .p-inputtextarea:focus::placeholder,
.contactus-form .p-inputtext:hover::placeholder,
.contactus-form .p-inputtextarea:hover::placeholder {
    color: #EE781C;
    font-size: 20px;
}

.contactus-form .p-inputtext:hover,
.contactus-form .p-inputtextarea:hover {
    border-bottom: 2px solid #EE781C !important;
}

.contactus-form .p-inputtext:focus,
.contactus-form .p-inputtextarea:focus {
    outline: none !important;
    border-bottom: 2px solid #EE781C !important;
    box-shadow: none !important;
}


.contactus-form button {
    padding: 1rem 2rem;
    background-color: transparent;
    color: white;
    border: 2px solid #EE781C;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
}

.submit-btn {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactus-form button:hover {
    background-color: #EE781C;
}

.contact-us-section-sales {
    background-image: url("../Assets/ContactUs/Background-image.svg");
}

.contact-us-section-sales-overlay {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.895);
}

.contact-us-section-sales-content {
    width: 100%;
    color: #FFF;
}

.contact-us-section-sales-content h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.contact-us-section-sales-content p {
    font-size: 22px;
    line-height: 1.8;
}

.contact-us-section-sales-images {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 100px;
}

.contact-us-section-sales-images .p-card .p-card-body,
.contact-us-section-sales-images .p-card .p-card-content {
    padding: 0;
}

.contact-us-section-sales img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;

}

.success-messages {
    margin-top: 1rem;

    .success-message-contents {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;

        .check-icon {
            color: #00c400;
            font-size: 28px;
        }

        .success-message-headings {
            font-weight: 600;
            margin-right: 1rem;

        }
    }
}

@media screen and (max-width: 1440px) {

    .contactus-section-banner-content-section h2 {
        font-size: 40px;
        line-height: 1.7;
    }

    .contactus-section-banner-content-section p {
        font-size: 20px;
        line-height: 1.7;
    }

    .contactus-form .p-inputtext,
    .contactus-form .p-inputtextarea {
        margin-bottom: 10px;
    }

    .contact-us-section-sales-overlay {
        display: flex;
        flex-wrap: wrap;
    }

    .contact-us-section-sales-images {
        width: 100%;
        display: flex;
        gap: 100px;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1200px) {

    .contactus-section-banner-content-section {
        flex-wrap: wrap;

        h2 {
            font-size: 34px;
            text-align: start;
        }
    }

    .contactus-section-banner-content {
        width: 100%;
        text-align: center;
    }

    .contact-us-section-sales {
        flex-wrap: wrap;
        text-align: center;
    }

    .contact-us-section-sales-content {
        width: 100%;
        margin-bottom: 2rem;
    }

    .contactus-section-banner-form {
        width: 100%;
    }

    .flex_D_K {
        flex-direction: column;
    }
}

@media screen and (max-width: 824px) {

    .contactus-section-banner-content-section {

        h2 {
            font-size: 25px;
        }

        .text-with-dot {
            width: 10px;
            height: 10px;
        }
    }

    .contactus-section-banner-form .w-max {
        width: -webkit-fill-available !important;
    }

    .contact-us-section-sales-overlay {
        display: flex;
        justify-content: space-around;
    }

    .contact-us-section-sales-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .location-cardds {
        margin-top: 0;
    }
}



@media screen and (max-width: 800px) {

    .contactus-section-banner-content-section p {
        font-size: 16px;
        line-height: 1.6;
        text-align: justify;
    }

    .contact-us-section-sales-images {
        gap: unset;
    }
}

@media screen and (max-width: 575px) {

    .contact-us-section-sales-content h2 {
        font-size: 30px;
    }

    .contact-us-section-sales-content p {
        font-size: 16px;
    }
}