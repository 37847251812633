.ambitious-section {
    text-align: center;
    padding: 4rem;
    background-color: #141414;
    color: #FFFFFF;

    .ambitious-section-content {
        display: flex;
        flex-direction: column;

        h2 {
            font-size: 36px;
        }

        p {
            font-size: 22px;
            line-height: 1.6;
            color: #CBCBCB;
        }
    }
}

.b_Bg {
    background: url('../../Assets/WhoWeAre/mesh-gradient\ 2.jpg');
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


@media screen and (max-width: 1200px) {
    .ambitious-section {
        padding: 3rem;
    }

    .ambitious-section-content {
        h2 {
            font-size: 30px !important;
        }

        p {
            font-size: 22px !important;
        }
    }

}

@media screen and (max-width: 490px) {
    .ambitious-section {
        padding: 2rem;
    }

    .ambitious-section-content {
        h2 {
            font-size: 17px !important;
        }

        p {
            font-size: 16px !important;
        }
    }
}